import React from "react";
import { Button, Flex } from "@chakra-ui/react";

const Pagination = ({ currentPage, totalPages, onPageChange, limit }) => {
  const MAX_VISIBLE_PAGES = limit || 5;
  const pages = [];

  const startPage = Math.max(1, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
  const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <Flex justify="center" align="center" mt={4}>
      <Button
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
        mr={2}
        colorScheme="blue"
        variant="solid"
      >
        Anterior
      </Button>
      {pages.map((page) => (
        <Button
          key={page}
          variant={currentPage === page ? "solid" : "outline"}
          colorScheme={currentPage === page ? "blue" : "gray"}
          onClick={() => onPageChange(page)}
          size="sm"
          mx={1}
        >
          {page}
        </Button>
      ))}
      <Button
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
        ml={2}
        colorScheme="blue"
        variant="solid"
      >
        Próxima
      </Button>
    </Flex>
  );
};

export default Pagination;
