/* eslint-disable no-unused-vars */
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
useGlobalFilter,
usePagination,
useSortBy,
useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import { lightFormat } from "date-fns";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getNameWithExtensionFromUrl } from "utils/masks";
import { CreateNotifications } from "services/notifications";

export default function ColumnsTable(props) {
  const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt } = props;

  const { user } = useAuthContext();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const tableInstance = useTable(
      {
      columns,
      data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
  );

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
      setIsOpen(false);
      setTitle('');
      setDescription('');
  };

  const onSaveBanner = async () => {
    try {
      setIsLoading(true);
      const response = await CreateNotifications(title, description, user?.token);
      setIsLoading(false);
      if (response?.status === 200 || response?.status === 201) {
          toast(response?.message || 'Notificação enviada com sucesso!');
          onClose();
          getList();
      } else {
        toast('Ocorreu um erro ao enviar notificação!');
      }
    } catch (error) {
      toast('Ocorreu um erro ao enviar notificação!');
    }
  }

return (
  <Card
    direction='column'
    w='100%'
    px='0px'
    overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex justifyContent="flex-end" padding="0px 20px 0px 20px" mb="20px">
          <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant='brand'>
              Criar notificação
          </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
          <ModalOverlay />
          <ModalContent>
              <ModalHeader>
                  Criar notificação
                  <Text color='secondaryGray.700' fontSize='xs' fontWeght='400'>{`Preencha as informações abaixo para criar uma notificação`}</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody display="flex" flexDirection="column" alignItems="center">
                  <FormControl>
                      <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'
                      >
                          Título
                      </FormLabel>
                      <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          placeholder='Título'
                          fontWeight='500'
                          size='lg'
                          mb="20px"
                          value={title}
                          onChange={(txt) => {
                            const value = txt.target.value;
                            setTitle(value);
                          }}
                      />
                  </FormControl>
                  <FormControl>
                      <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'
                      >
                          Descrição
                      </FormLabel>
                      <Textarea
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          placeholder='Descrição'
                          fontWeight='500'
                          size='lg'
                          borderWidth="1px"
                          resize="none"
                          borderRadius="20px"
                          minH="150px"
                          css={{
                              '::placeholder': {
                                  color: '#A3AED0',
                                  fontWeight: 400,
                              }
                          }}
                          mb="20px"
                          value={description}
                          onChange={(txt) => {
                            const value = txt.target.value;
                            setDescription(value);
                          }}
                      />
                  </FormControl>
                  <Button
                      variant='brand'
                      alignSelf="flex-end"
                      w="150px"
                      marginTop="24px"
                      onClick={onSaveBanner}
                      isLoading={isLoading}
                  >
                      Criar notificação
                  </Button>
              </ModalBody>

              <ModalFooter>
              </ModalFooter>
          </ModalContent>
      </Modal>
    <Table {...getTableProps()} variant='simple' color='gray.500' mb='100px'>
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                pe='10px'
                key={index}
                borderColor={borderColor}>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                  {column.render("Header")}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, index) => {
                let data = "";
                if (cell.column.Header === "TÍTULO") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700' maxW='200px' isTruncated>
                        {cell.value}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "DESCRIÇÃO") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700' maxW='200px' isTruncated>
                        {cell.value}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "CRIADO EM") {
                  data = (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  );
                }
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    {data}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
      <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
      />
      <ToastContainer />
  </Card>
);
}
