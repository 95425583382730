export const columnsDataColumns = [
    {
        Header: "NOME",
        accessor: "token_name",
    },
    {
        Header: "TIPO",
        accessor: "type",
    },
    {
        Header: "ÚLTIMO LANCE",
        accessor: "bid",
    },
    {
        Header: "VALOR UNITÁRIO/LANC. MIN.",
        accessor: "value_unity",
    },
    {
        Header: "VALOR ARREMATADO",
        accessor: "auctions_bids_value",
    },
    {
        Header: "TEMPO DE OFERTA",
        accessor: "offers_end_at",
    },
    {
        Header: "SITUAÇÃO",
        accessor: "blocked_at",
    },
    {
        Header: "CRIADO EM",
        accessor: "created_at",
    },
    {
        Header: "AÇÕES",
        accessor: "actions",
    },
];
