/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
Flex,
FormControl,
FormErrorMessage,
FormLabel,
Grid,
Icon,
Image,
Input,
Modal,
ModalBody,
ModalCloseButton,
ModalContent,
ModalFooter,
ModalHeader,
ModalOverlay,
Select,
Stack,
Table,
Tbody,
Td,
Text,
Th,
Thead,
Tr,
useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useMemo, useState } from "react";
import {
useGlobalFilter,
usePagination,
useSortBy,
useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
import Dropzone from "views/admin/profile/components/Dropzone";
import { MdGeneratingTokens } from "react-icons/md";
import { addHours, lightFormat } from "date-fns";
import { toPrice } from "utils/masks";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CancelAuctions } from "services/auctions";

export default function ColumnsTable(props) {
  const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, type, setType, orderBy, setOrderBy } = props;

  const { user } = useAuthContext();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const tableInstance = useTable(
      {
      columns,
      data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
  );

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const brandColor = useColorModeValue("brand.500", "white");

  const [modalConfirmCanceled, setModalConfirmCanceled] = useState(false);
  const [infoRow, setInfoRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onCloseCanceled = () => {
      setModalConfirmCanceled(false);
      setInfoRow(null);
  };

  const onClickCancel = async (item) => {
    setInfoRow(item);
    setModalConfirmCanceled(true);
  };

  const onClickCancelConfirm = async () => {
    try {
      setIsLoading(true);
      await CancelAuctions(infoRow?.id, user?.token);
      setIsLoading(false);
      getList();
      onCloseCanceled();
    } catch (error) {
      toast('Ocorreu um erro ao cancelar o leilão!');
    }
  };

return (
  <Card
    direction='column'
    w='100%'
    px='0px'
    overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex justifyContent="space-between" padding="0px 20px 0px 20px" mb="20px">
        <Stack>
          <Text
            me='auto'
            color={textColor}
            fontSize='md'
            fontWeight='700'
            lineHeight='100%'>
            Tipo de leilão:
          </Text>
          <Select
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              fontWeight='500'
              size='lg'
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
              w="200px"
          >
              <option value='all'>Todos os leilões</option>
              <option value='direta'>Direta</option>
              <option value='oferta'>Oferta</option>
          </Select>
        </Stack>
        <Stack>
          <Text
            me='auto'
            color={textColor}
            fontSize='md'
            fontWeight='700'
            lineHeight='100%'>
            Ordenar por:
          </Text>
          <Select
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              fontWeight='500'
              size='lg'
              value={orderBy}
              onChange={(e) => {
                setOrderBy(e.target.value);
              }}
              w="200px"
          >
              <option value="">Nenhum</option>
              <option value='highest_bids'>Maiores lances</option>
          </Select>
        </Stack>
      </Flex>
      <Modal isOpen={modalConfirmCanceled} onClose={onCloseCanceled} isCentered scrollBehavior="inside" size="lg">
          <ModalOverlay />
          <ModalContent>
              <ModalHeader>
                  Cancelar leilão em andamento
                  <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>Tem certeza que deseja cancelar esse leilão?</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalFooter gap={2}>
                <Button
                    variant='brand'
                    alignSelf="flex-end"
                    w="150px"
                    marginTop="24px"
                    onClick={onCloseCanceled}
                    isLoading={isLoading}
                >
                    Fechar
                </Button>
                <Button
                    // variant=""
                    onClick={onClickCancelConfirm}
                    isLoading={isLoading}
                    alignSelf="flex-end"
                    w="200px"
                    marginTop="24px"
                    bg="red.600"
                    color="white"
                >
                    CANCELAR LEILÃO
                </Button>
              </ModalFooter>
          </ModalContent>
      </Modal>
    <Table {...getTableProps()} variant='simple' color='gray.500' mb='100px'>
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                pe='10px'
                key={index}
                borderColor={borderColor}>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                  {column.render("Header")}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, index) => {
                let data = "";
                if (cell.column.Header === "AÇÕES" && !cell.row.original.blocked_at) {
                  return (
                      <Flex align='center' pl="30px" pt="5px">
                          <Menu row={cell.row.original} onClickCancel={onClickCancel} />
                      </Flex>
                  )
                } else if (cell.column.Header === "TIPO") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "NOME") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "VALOR UNITÁRIO/LANC. MIN.") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {`R$ ${toPrice(cell.row.original.value_unity || cell.row.original.min_value || 0, 2)}`}
                      </Text>
                    </Flex>
                  );
                 } else if (cell.column.Header === "VALOR ARREMATADO") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {!cell.row.original.auctions_bids_value ? '-' : `R$ ${toPrice(cell.row.original.auctions_bids_value || 0, 2)}`}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "SITUAÇÃO") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value ? 'Encerrado' : 'Em andamento'}
                      </Text>
                    </Flex>
                  );
                }  else if (cell.column.Header === "ÚLTIMO LANCE") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {!cell.row.original?.bid?.id ? 'Sem lance' : `${cell.row.original?.bid?.user_name} - R$ ${toPrice(cell.row.original?.bid?.value || 0, 2)}`}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "CRIADO EM") {
                  data = (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  );
                } else if (cell.column.Header === "TEMPO DE OFERTA") {
                  data = (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {lightFormat(new Date(addHours(cell.value, 3)), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  );
                }
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    {data}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
      <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
      />
      <ToastContainer />
  </Card>
);
}
