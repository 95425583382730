import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
//   Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
// import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from 'hooks/use-auth';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

function SignIn() {
    const auth = useAuth();

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    // const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    // const googleText = useColorModeValue("navy.700", "white");
    // const googleHover = useColorModeValue(
    //     { bg: "gray.200" },
    //     { bg: "whiteAlpha.300" }
    // );
    // const googleActive = useColorModeValue(
    //     { bg: "secondaryGray.300" },
    //     { bg: "whiteAlpha.200" }
    // );
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
        password: Yup.string().required('Senha é obrigatória'),
    });

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'
            >
                <Box me='auto'>
                    <Heading color={textColor} fontSize='36px' mb='10px'>
                        Login
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='md'
                    >
                        Digite seu e-mail e senha para entrar!
                    </Text>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}
                >
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                const response = await auth.signIn(values.email, values.password);
                                helpers.setSubmitting(false);
                                if (response?.status !== 200) {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response.message });
                                    helpers.setSubmitting(false);
                                } else {
                                    // window.location.replace('/')
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form>
                                <Field name='email'>
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.email && form.touched.email}>
                                            <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color={textColor}
                                                mb='8px'
                                            >
                                                E-mail<Text color={brandStars}>*</Text>
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                type='email'
                                                placeholder='Seu e-mail'
                                                fontWeight='500'
                                                size='lg'
                                            />
                                            <FormErrorMessage mb='24px'>{form.errors.email}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='password'>
                                    {({ field, form }) => (
                                        <FormControl marginTop="24px" isInvalid={form.errors.password && form.touched.password}>
                                            <FormLabel
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color={textColor}
                                                display='flex'>
                                                Senha<Text color={brandStars}>*</Text>
                                            </FormLabel>
                                            <InputGroup size='md'>
                                                <Input
                                                    {...field}
                                                    fontSize='sm'
                                                    placeholder='Sua senha'
                                                    size='lg'
                                                    type={show ? "text" : "password"}
                                                    variant='auth'
                                                />
                                                <InputRightElement display='flex' alignItems='center' mt='4px'>
                                                    <Icon
                                                        color={textColorSecondary}
                                                        _hover={{ cursor: "pointer" }}
                                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                        onClick={handleClick}
                                                    />
                                                </InputRightElement>
                                            </InputGroup>
                                            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Flex marginTop="24px" justifyContent='space-between' align='center' mb='24px'>
                                    <NavLink to='/auth/forgot-password'>
                                        <Text
                                            color={textColorBrand}
                                            fontSize='sm'
                                            w='140px'
                                            fontWeight='500'
                                        >
                                            Esqueceu sua senha?
                                        </Text>
                                    </NavLink>
                                </Flex>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="24px"
                                        mb="24px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    fontSize='sm'
                                    variant='brand'
                                    fontWeight='500'
                                    w='100%'
                                    h='50'
                                    mb='24px'
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                >
                                    Entrar
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='start'
                        maxW='100%'
                        mt='0px'
                    >
                        <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                            Não registrado ainda?
                            <NavLink to='/auth/sign-up'>
                                <Text
                                    color={textColorBrand}
                                    as='span'
                                    ms='5px'
                                    fontWeight='500'
                                >
                                    Crie a sua conta aqui
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignIn;
