/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
  Box,
  // SimpleGrid,
} from "@chakra-ui/react";
import ColumnsTable from "views/admin/banners/components/ColumnsTable";
import {
columnsDataColumns,
} from "views/admin/banners/variables/columnsData";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import { ListExtracts } from "services/extracts";
import { ListBanners } from "services/banners";

export default function Banners() {
  const { user } = useAuthContext();

  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTxt, setSearchTxt] = useState('');

  useEffect(() => {
      const timeOutId = setTimeout(() => {
          getList();
      }, 500);
      return () => clearTimeout(timeOutId);
  }, [currentPage, searchTxt]);

  const getList = async () => {
      const response = await ListBanners(currentPage, user?.token);
      setList(response?.result?.list || []);
      setTotalPages(response?.result?.qtdPages || 1);
  };

// Chakra Color Mode
return (
  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={list}
          getList={getList}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTxt={searchTxt}
          setSearchTxt={setSearchTxt}
      />
  </Box>
);
}
