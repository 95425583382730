// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";
import { toPrice } from "utils/masks";

export default function Default(props) {
  const { startContent, endContent, name, allLabels, value } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Card py='15px'>
      <Flex
        my='auto'
        h='100%'
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}>
        {startContent}

        <Stat my='auto' ms={startContent ? "18px" : "0px"}>
            <StatLabel
                lineHeight='100%'
                color={textColorSecondary}
                fontSize={{
                    base: "2x",
                }}
                mb={allLabels?.length ? '10px' : '0px'}
            >
                {name}
            </StatLabel>
            {allLabels?.length ? (allLabels || []).map((v) => (
                <Flex key={v.label} flexDirection="column">
                    <Text color='secondaryGray.600' fontSize='sm' fontWeight='400' me='5px'>
                        {`${v.label}: `}
                    </Text>
                    <Text color='red.600' fontSize='xs' fontWeight='700'>
                        {v?.format ? `R$${toPrice(v.value, 2)}` : v.value}
                    </Text>
                </Flex>
            )) : (
                <StatNumber
                    color={textColor}
                    fontSize={{
                    base: "2xl",
                    }}>
                    {value}
                </StatNumber>
            )}
        </Stat>
        <Flex ms='auto' w='max-content'>
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
