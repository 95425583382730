import { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { RefreshToken, Signin } from '../services/auth';
// import { login } from '../services/auth';

const HANDLERS = {
  INITIALIZE: 'INITIALIZE',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT'
};

const initialState = {
  isAuthenticated: false,
  isLoading: true,
  user: null
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      ...(
        // if payload (user) is provided, then is authenticated
        user
          ? ({
            isAuthenticated: true,
            isLoading: false,
            user
          })
          : ({
            isLoading: false
          })
      )
    };
  },
  [HANDLERS.SIGN_IN]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  [HANDLERS.SIGN_OUT]: (state) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null
    };
  }
};

const reducer = (state, action) => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

// The role of this context is to propagate authentication state through the App tree.

export const AuthContext = createContext({ undefined });

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);

  const initialize = async () => {
    // Prevent from calling twice in development mode with React.StrictMode enabled
    if (initialized.current) {
      return;
    }

    initialized.current = true;

    let dataUser = null;

    try {
        const dataUserSave = window.localStorage.getItem('@dsstoken_authenticated');
        const dataUserParse = JSON.parse(dataUserSave);
        const response = await RefreshToken(dataUserParse.tokenRefresh, dataUserParse.user.typeUser);
        if (response.result.token) {
            dataUser = {
                ...dataUserParse,
                token: response.result.token,
            };
        }
    } catch (err) {
      console.error(err);
    }

    if (dataUser?.token) {
      dispatch({
        type: HANDLERS.INITIALIZE,
        payload: dataUser
      });
    } else {
      dispatch({
        type: HANDLERS.INITIALIZE
      });
    }
  };

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = async (email, password) => {
    const responseAuth = await Signin(email, password);
    if (responseAuth?.status === 200) {
        const user = responseAuth.result;
        window.localStorage.setItem('@dsstoken_authenticated', JSON.stringify(user));
    
        dispatch({
          type: HANDLERS.SIGN_IN,
          payload: user
        });
    }
    return responseAuth;
  };

  const signUp = async (email, name, password) => {
    throw new Error('Sign up is not implemented');
  };

  const signOut = () => {
    window.localStorage.removeItem('@dsstoken_authenticated');
    dispatch({
      type: HANDLERS.SIGN_OUT
    });
  };

  const updateDataUser = (user) => {
    window.localStorage.setItem('@dsstoken_authenticated', JSON.stringify(user));
    dispatch({
      type: HANDLERS.SIGN_IN,
      payload: user
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        updateDataUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);

export const SidebarContext = createContext();
