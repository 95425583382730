// libraries
import styled from 'styled-components';

// styles
export const Container = styled.div`
    background-color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: overlay;
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        background: white;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: gray;
    }
`;

export const Home = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    padding: 20px;
`;

export const Title = styled.text`
    color: black;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 24px;
    text-align: center;
`;

export const SubTitle = styled.text`
    color: black;
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
`;

export const Description = styled.text`
    margin-top: 20px;
    color: gray;
    font-size: 16px;
    font-weight: 600;
`;

export const SubDescription = styled.text`
    margin-top: 5px;
    color: gray;
    font-size: 14px;
    font-weight: 500;
    padding-left: 20px;
`;
