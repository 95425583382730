import { api } from './api';

export async function ListBanners(page, token) {
    try {
        let baseURL = `/banners?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateBanner(position, file, token) {
    try {
        const response = await api.post('/banners', { position, file }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditBanner(position, file, idBanner, token) {
    try {
        const response = await api.put(`/banners?idBanner=${idBanner}`, { position, file }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteBanner(idBanner, token) {
  try {
      const response = await api.delete(`/banners?idBanner=${idBanner}`, {
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}
