import { api } from './api';

export async function ListUsers(idUser, searchTxt, page, limit, token) {
    try {
        let baseURL = `/users?limit=${limit || 10}`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateUser(type, name, document, telephone, cep, address, city, neighborhood, district, numberAddress, birthDate, email, password, token) {
    try {
        const response = await api.post('/users', { type, name, document, telephone, cep, address, city, neighborhood, district, numberAddress, birthDate, email, password }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditUser(type, name, document, telephone, cep, address, city, neighborhood, district, numberAddress, birthDate, email, password, idUser, token) {
    try {
        const response = await api.put(`/users?idUser=${idUser}`, { type, name, document, telephone, cep, address, city, neighborhood, district, numberAddress, birthDate, email, password }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function BlockUser(idUser, token) {
    try {
        const response = await api.patch('/users/blocked', { idUser }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteUser(idUser, token) {
    try {
        const response = await api.delete(`/users?idUser=${idUser}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
