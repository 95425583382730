import { api } from './api';

export async function ListTokens(idUser, searchTxt, page, type, token) {
    try {
        let baseURL = `/tokens?limit=10&type=${type}&searchTxt=${searchTxt}`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function ListTokensExchange(idUser, searchTxt, page, token) {
  try {
      let baseURL = `/tokens/exchange?limit=10&searchTxt=${searchTxt}`;
      if (page) {
          baseURL = `${baseURL}&page=${page}`
      }
      if (idUser) {
          baseURL = `${baseURL}&idUser=${idUser}`
      }
      const response = await api.get(baseURL, {
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}

export async function ListUniqueTokens(idOrder, searchTxt, page, token) {
    try {
        let baseURL = `/tokens/unique?limit=999999999&searchTxt=${searchTxt}`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        const response = await api.patch(baseURL, { idOrder }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function ListTokensClient(idToken, token) {
    try {
        let baseURL = `/tokens/client`;
        if (idToken) {
            baseURL = `${baseURL}?idToken=${idToken}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function ListTokensForType(idToken, idUser, searchTxt, page, type, limit, token) {
    try {
        let baseURL = `/tokens/adm?limit=${limit || 10}&type=${type}&searchTxt=${searchTxt}`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (idToken) {
            baseURL = `${baseURL}&idToken=${idToken}`
        }
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateToken(logo, sigla, name, qtd, value, minimumValue, percentageOfRemuneration, remunerationPeriod, contract, characteristics, images, files, startDateOffers, endDateOffers, monthsLifetime, token) {
    try {
        const response = await api.post('/tokens', { logo, sigla, name, qtd, value, minimumValue, percentageOfRemuneration, remunerationPeriod, contract, characteristics, images, files, startDateOffers, endDateOffers, monthsLifetime }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditToken(logo, sigla, name, qtd, value, minimumValue, percentageOfRemuneration, remunerationPeriod, contract, characteristics, images, files, startDateOffers, endDateOffers, monthsLifetime, idToken, token) {
    try {
        const response = await api.put(`/tokens?idToken=${idToken}`, { logo, sigla, name, qtd, value, minimumValue, percentageOfRemuneration, remunerationPeriod, contract, characteristics, images, files, startDateOffers, endDateOffers, monthsLifetime }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function BlockToken(idToken, token) {
    try {
        const response = await api.patch('/tokens/blocked', { idToken }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteToken(idToken, token) {
    try {
        const response = await api.delete(`/tokens?idToken=${idToken}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteHash(idHash, token) {
    try {
        const response = await api.delete(`/tokens/hash?idHash=${idHash}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
