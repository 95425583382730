import React from "react";

// Chakra imports
import {
    Box, Flex, Image,
    // useColorModeValue
} from "@chakra-ui/react";

// Custom components
// import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import logoWhite from 'assets/img/logoWhite.png'

export function SidebarBrand() {
  //   Chakra color mode
//   let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <Box style={{ marginBottom: '10px',backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', width: '150px' }}>
        <Image h="80px" my="32px" src={logoWhite} />
      </Box>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
