/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
Flex,
FormControl,
FormErrorMessage,
FormLabel,
Grid,
Icon,
Image,
Input,
Modal,
ModalBody,
ModalCloseButton,
ModalContent,
ModalFooter,
ModalHeader,
ModalOverlay,
Table,
Tbody,
Td,
Text,
Th,
Thead,
Tr,
useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useMemo, useState } from "react";
import {
useGlobalFilter,
usePagination,
useSortBy,
useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
import Dropzone from "views/admin/profile/components/Dropzone";
import { MdDocumentScanner, MdEditDocument, MdFileCopy, MdFileOpen, MdGeneratingTokens } from "react-icons/md";
import { lightFormat } from "date-fns";
import { toPrice } from "utils/masks";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UpdateWithdraw } from "services/extracts";
import { getNameWithExtensionFromUrl } from "utils/masks";

export default function ColumnsTable(props) {
  const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt } = props;

  const { user } = useAuthContext();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const tableInstance = useTable(
      {
      columns,
      data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
  );

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const brandColor = useColorModeValue("brand.500", "white");
  // const bg = useColorModeValue("gray.100", "navy.700");
  // const borderColorArchive = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  const [isOpen, setIsOpen] = useState(false);
  const [modalConfirmCanceled, setModalConfirmCanceled] = useState(false);
  const [infoRow, setInfoRow] = useState(null);
  const [proof, setProof] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
      setIsOpen(false);
      setInfoRow(null);
      setProof('');
  };

  const onCloseCanceled = () => {
    setModalConfirmCanceled(false);
    setInfoRow(null);
};

  const onClickEdit = (item) => {
      setInfoRow(item);
      setIsOpen(true);
  };

  const onClickCancel = async (item) => {
    setInfoRow(item);
    setModalConfirmCanceled(true);
  };

  const onClickCancelConfirm = async () => {
    try {
      setIsLoading(true);
      await UpdateWithdraw(infoRow?.id, undefined, 'canceled', user?.token);
      setIsLoading(false);
      getList();
      onCloseCanceled();
    } catch (error) {
      toast('Ocorreu um erro ao cancelar o saque!');
    }
  };

  const onFinishWithdraw = async () => {
    try {
      setIsLoading(true);
      const response = await UpdateWithdraw(infoRow?.id, proof, 'finish', user?.token);
      setIsLoading(false);
      if (response?.status === 200 || response?.status === 201) {
          toast(response?.message || 'Token salvo com sucesso!');
          onClose();
          getList();
      } else {
        toast('Ocorreu um erro ao finalizar o saque!');
      }
    } catch (error) {
      toast('Ocorreu um erro ao finalizar o saque!');
    }
  }

return (
  <Card
    direction='column'
    w='100%'
    px='0px'
    overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex justifyContent="space-between" padding="0px 20px 0px 20px" mb="20px">
          <SearchBar
              placeholder="Buscar fatura"
              value={searchTxt}
              setValue={setSearchTxt}
          />
          {/* <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant='brand'>
              Criar fatura
          </Button> */}
      </Flex>

      <Modal isOpen={modalConfirmCanceled} onClose={onCloseCanceled} isCentered scrollBehavior="inside" size="lg">
          <ModalOverlay />
          <ModalContent>
              <ModalHeader>
                  Cancelar saque
                  <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>Tem certeza que deseja cancelar esse saque?</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalFooter gap={2}>
                <Button
                    variant='brand'
                    alignSelf="flex-end"
                    w="150px"
                    marginTop="24px"
                    onClick={onCloseCanceled}
                    isLoading={isLoading}
                >
                    Fechar
                </Button>
                <Button
                    // variant=""
                    onClick={onClickCancelConfirm}
                    isLoading={isLoading}
                    alignSelf="flex-end"
                    w="200px"
                    marginTop="24px"
                    bg="red.600"
                    color="white"
                >
                    CANCELAR SAQUE
                </Button>
              </ModalFooter>
          </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
          <ModalOverlay />
          <ModalContent>
              <ModalHeader>
                  {infoRow ? 'Editar fatura' : 'Criar fatura'}
                  <Text color='secondaryGray.700' fontSize='xs' fontWeght='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} uma fatura`}</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody display="flex" flexDirection="column" alignItems="center">
                  <Dropzone
                      minH="60px"
                      w="100%"
                      borderRadius="10px"
                      mb="24px"
                      onImageSelect={(file) => setProof(file[0] || '')}
                      multiple={false}
                      accept="application/pdf"
                      content={
                          <Box display="flex" flexDirection="column" gap="10px">
                              {proof.length > 0 ? (
                                  <Text color='blue.500' fontSize='xs' fontWeight='400'>{getNameWithExtensionFromUrl(String(proof))}</Text>
                              ) : (
                                  <>
                                      <Text color='blue.500' fontSize='xs' fontWeight='400'>Clique aqui para selecionar o CONTRATO.</Text>
                                      <Text color='secondaryGray.700' fontSize='10px' fontWeight='400'>Formatos aceitos: PDF</Text>
                                  </>
                              )}
                          </Box>
                      }
                  />
                  <Button
                      variant='brand'
                      alignSelf="flex-end"
                      w="150px"
                      marginTop="24px"
                      onClick={onFinishWithdraw}
                  >
                      Finalizar saque
                  </Button>
              </ModalBody>

              <ModalFooter>
              </ModalFooter>
          </ModalContent>
      </Modal>
    <Table {...getTableProps()} variant='simple' color='gray.500' mb='100px'>
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                pe='10px'
                key={index}
                borderColor={borderColor}>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                  {column.render("Header")}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, index) => {
                let data = "";
                if (cell.column.Header === "AÇÕES" && cell.row.original?.status === 'pending') {
                  return (
                      <Flex align='center' pl="30px" pt="5px">
                          <Menu row={cell.row.original} onClickEdit={onClickEdit} onClickCancel={onClickCancel} />
                      </Flex>
                  )
                } else if (cell.column.Header === "NOME DO USUÁRIO") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "SITUAÇÃO") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value === 'pending' ? 'Pendente' : (cell.value === 'canceled' ? 'Cancelado' : 'Finalizado')}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "CRIADO EM") {
                  data = (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  );
                }
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    {data}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
      <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
      />
      <ToastContainer />
  </Card>
);
}
