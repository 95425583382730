export const columnsDataColumns = [
  {
      Header: "TÍTULO",
      accessor: "title",
  },
  {
      Header: "DESCRIÇÃO",
      accessor: "description",
  },
  {
      Header: "CRIADO EM",
      accessor: "created_at",
  },
];
