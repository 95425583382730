// Chakra imports
import {
  Box,
//   Button,
  Flex,
//   Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import BarChart from "components/charts/BarChart";
import React from "react";
import {
//   barChartDataConsumption,
  barChartOptionsConsumption,
} from "variables/charts";
// import { MdBarChart } from "react-icons/md";

export default function WeeklyRevenue(props) {
  const { result, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
//   const iconColor = useColorModeValue("brand.500", "white");
//   const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
//   const bgHover = useColorModeValue(
//     { bg: "secondaryGray.400" },
//     { bg: "whiteAlpha.50" }
//   );
//   const bgFocus = useColorModeValue(
//     { bg: "secondaryGray.300" },
//     { bg: "whiteAlpha.100" }
//   );
  return (
    <Card align='center' direction='column' w='100%' {...rest}>
        <Flex justify='space-between' ps='0px' pe='20px' pt='5px'>
        <Flex align='center' w='100%'>
            <Text
            me='auto'
            color={textColor}
            fontSize='xl'
            fontWeight='700'
            lineHeight='100%'>
            Tokens mais vendidos
            </Text>
        </Flex>
        </Flex>
        <Text
        me='auto'
        color={textColor}
        fontSize='sm'
        fontWeight='700'
        marginTop="10px"
        lineHeight='100%'>
        Última semana
        </Text>

      <Box h='240px' mt='auto'>
        <BarChart
            chartData={result?.barChartDataConsumption || []}
            chartOptions={{ ...barChartOptionsConsumption, xaxis: { ...barChartOptionsConsumption.xaxis, categories: result?.week } }}
        />
      </Box>
    </Card>
  );
}
