/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
} from "@chakra-ui/react";

// Custom components
import HistoryItem from "views/client/offers/components/HistoryItem";
import Card from "components/card/Card.js";

// Assets
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { ListTokensForType } from "services/tokens";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ListTokensClient } from "services/tokens";
import { ModalOrder } from "components/modalOrder";

export default function Tokens() {
    const { user } = useAuthContext();

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [infoToken, setInfoToken] = useState(null);
    const [step, setStep] = useState(1);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPage, searchTxt]);

    useEffect(() => {
        getRedirectToken();
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getRedirectToken = async () => {
        const idToken = window.sessionStorage.getItem('@dsstoken_idToken');
        const qtdToken = window.sessionStorage.getItem('@dsstoken_qtdToken');
        if (idToken && qtdToken) {
            window.sessionStorage.removeItem('@dsstoken_idToken');
            window.sessionStorage.removeItem('@dsstoken_qtdToken');
            const response = await ListTokensClient(Number(idToken), user?.token);
            setInfoToken(response?.result?.list?.length > 0 ? { ...response?.result?.list[0], pdf: response?.result?.list[0]?.contract, qtd: Number(qtdToken) } : null);
            setIsOpen(true);
        }
    };

    const getList = async () => {
        const response = await ListTokensForType(null, user?.user?.id, searchTxt, currentPage, 'offers', null, user?.token);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
    };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
            {list?.length > 0 ? (
                <Card p='0px 0px 20px 0px'>
                    <SearchBar
                        style={{ marginLeft: 20, marginTop: 20 }}
                        placeholder="Buscar token"
                        value={searchTxt}
                        setValue={setSearchTxt}
                    />
                    {(list || []).map((v) => (
                        <HistoryItem
                            name={v.name}
                            author={`Quantidade disponível: ${v.qtd}`}
                            date={v.created_at}
                            image={v.logo}
                            price={v.value}
                            key={v.id}
                            onClick={() => {
                                // https://dsstoken.com
                                // http://localhost:3000
                                window.location.replace(`https://dsstoken.com/token?id=${v.id}`);
                            }}
                        />
                    ))}
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Card>
            ) : null}
        </Flex>

        <ModalOrder isOpen={isOpen} setIsOpen={setIsOpen} infoToken={infoToken} setInfoToken={setInfoToken} getList={getList} isLoading={isLoading} setIsLoading={setIsLoading} step={step} setStep={setStep} />
      {/* Delete Product */}
    </Box>
  );
}
