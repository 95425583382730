import { api } from './api';

export async function AutentiqueCreate(idToken, idUser, qtd, pdf, token) {
    try {
        const response = await api.post('/autentique/create', { idToken, idUser, qtd, pdf }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
