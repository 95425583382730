/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// libraries
import React from 'react';
import {
    Container,
    Home,
    Title,
    Description,
    SubTitle,
} from './styles';

// main
const PrivacyPolicyPage = () => (
    <Container>
        <Home>
            <Title style={{ marginTop: 0 }}>POLÍTICA DE PRIVACIDADE</Title>
            <Description>Política de Privacidade e de Proteção de Dados Pessoais</Description>
            <Description>
              A D Service Serviços Ltda, doravante referida como, tem o compromisso de
              proteger a privacidade e os dados pessoais de seus usuários. Esta Política
              de Privacidade e de Proteção de Dados Pessoais descreve como a D Service
              Serviços Ltda coleta, usa, armazena e protege as informações pessoais
              fornecidas pelos usuários em seus serviços online.
            </Description>
            <SubTitle>1. Coleta de Informações</SubTitle>
            <Description>
              1.1. A D Service Serviços Ltda coleta informações pessoais dos usuários
              quando estes interagem com seus serviços online, incluindo, mas não se
              limitando a, nome, endereço de e-mail, número de telefone e informações
              de pagamento.
            </Description>
            <Description>
              1.2. Além disso, a D Service Serviços Ltda também pode coletar informações
              não pessoais, como dados de acesso, endereço IP, tipo de navegador,
              provedor de serviços de Internet e páginas visitadas.
            </Description>
            <SubTitle>2. Uso das Informações</SubTitle>
            <Description>
              2.1. As informações pessoais dos usuários são utilizadas pela D Service
              Serviços Ltda para fornecer e melhorar seus serviços, processar transações,
              responder a solicitações de suporte e enviar comunicações relacionadas aos
              serviços.
            </Description>
            <Description>
              2.2. As informações não pessoais podem ser utilizadas para análise de
              tendências, administração do site, rastreamento dos movimentos dos
              usuários e para reunir informações demográficas
            </Description>
            <SubTitle>3. Compartilhamento de Informações</SubTitle>
            <Description>
              3.1. A D Service Serviços Ltda não vende, aluga ou compartilha informações
              pessoais dos usuários com terceiros, exceto quando necessário para
              fornecer os serviços solicitados pelos usuários ou conforme exigido por lei.
            </Description>
            <Description>
              3.2. Informações não pessoais podem ser compartilhadas com parceiros e
              provedores de serviços para fins de análise e melhoria dos serviços
              oferecidos pela D Service Serviços Ltda.
            </Description>
            <SubTitle>4. Segurança das Informações</SubTitle>
            <Description>
              4.1. A D Service Serviços Ltda implementa medidas de segurança adequadas
              para proteger as informações pessoais dos usuários contra acesso não
              autorizado, uso indevido ou divulgação.
            </Description>
            <Description>
              4.2. No entanto, é importante ressaltar que nenhum método de
              transmissão pela Internet ou armazenamento eletrônico é 100% seguro, e
              a D Service Serviços Ltda não pode garantir a segurança absoluta das
              informações transmitidas ou armazenadas.
            </Description>
            <SubTitle>5. Consentimento</SubTitle>
            <Description>
              5.1. Ao utilizar os serviços da D Service Serviços Ltda, os usuários consentem
              com a coleta, uso e compartilhamento de suas informações pessoais de
              acordo com esta Política de Privacidade e de Proteção de Dados Pessoais.
            </Description>
            <SubTitle>6. Alterações nesta Política</SubTitle>
            <Description>
              6.1. A D Service Serviços Ltda se reserva o direito de atualizar ou modificar
              esta Política de Privacidade e de Proteção de Dados Pessoais a qualquer
              momento. As alterações entrarão em vigor imediatamente após sua
              publicação no site da D Service Serviços Ltda.
            </Description>
            <SubTitle>7. Contato</SubTitle>
            <Description>
              7.1. Se os usuários tiverem dúvidas ou preocupações sobre esta Política de
              Privacidade e de Proteção de Dados Pessoais, podem entrar em contato
              com a D Service Serviços Ltda através dos canais de suporte disponibilizados
              em seu site.
            </Description>
            <SubTitle>Esta Política de Privacidade e de Proteção de Dados Pessoais foi atualizada
            pela última vez em 27/02/2024.</SubTitle>
        </Home>
    </Container>
);
export default PrivacyPolicyPage;
