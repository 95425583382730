import { api } from './api';

// type: 'all' | 'direta' | 'oferta',
// orderBy: 'highest_bids' | undefined,
// idUser: number | undefined,
// idUserBid: number | undefined,
// page: number,
// token: string,
export async function ListAuctions(type, orderBy, idUser, idUserBid, page, token) {
    try {
        let baseURL = `/auctions?limit=10&type=${type}&blockedAt=true`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        if (idUserBid) {
            baseURL = `${baseURL}&idUserBid=${idUserBid}`
        }
        if (orderBy) {
            baseURL = `${baseURL}&orderBy=${orderBy}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CancelAuctions(idAuction, token) {
  try {
      const response = await api.patch(`/auctions/blocked`, { idAuction }, {
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}
