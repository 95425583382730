import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
//   Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
// import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { maskCPF } from "utils/masks";
import { SigninApp } from "services/auth";
import { ApplyExcludedAccount } from "services/auth";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function ExcludedAccount() {
    const mbCount = useBreakpointValue({ base: 10, lg: 0 });

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    // const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    // const googleText = useColorModeValue("navy.700", "white");
    // const googleHover = useColorModeValue(
    //     { bg: "gray.200" },
    //     { bg: "whiteAlpha.300" }
    // );
    // const googleActive = useColorModeValue(
    //     { bg: "secondaryGray.300" },
    //     { bg: "whiteAlpha.200" }
    // );
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const validationSchema = Yup.object().shape({
        cpf: Yup.string().required('CPF é obrigatório'),
        password: Yup.string().required('Senha é obrigatória'),
    });

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'
            >
                <Box me='auto'>
                    <Heading color={textColor} fontSize='36px' mb='10px'>
                        Exclusão de conta
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='md'
                    >
                        Digite seu e-mail e senha para excluir sua conta!
                    </Text>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}
                >
                    <Formik
                        initialValues={{ cpf: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                const response = await SigninApp(values.cpf, values.password);
                                console.log('response', response);
                                helpers.setSubmitting(false);
                                if (response?.status !== 200) {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response.message });
                                    helpers.setSubmitting(false);
                                } else {
                                    console.log('deu bom');
                                    const responseExcluded = await ApplyExcludedAccount(response?.result?.user?.id);
                                    console.log('responseExcluded', responseExcluded);
                                    toast(responseExcluded?.result?.message || 'Conta excluída com sucesso!');
                                    window.location.replace('/');
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form>
                                <Field name="cpf">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.cpf && form.touched.cpf}>
                                            <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color={textColor}
                                                mb='8px'
                                            >
                                                CPF<Text color={brandStars}>*</Text>
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Seu documento'
                                                fontWeight='500'
                                                size='lg'
                                                onChange={(txt) => {
                                                  const formatCPF = maskCPF(txt.target.value);
                                                  if (formatCPF.length <= 14) {
                                                      props.handleChange({
                                                          target: {
                                                              name: 'cpf',
                                                              value: formatCPF,
                                                          }
                                                      });
                                                  }
                                                }}
                                            />
                                            <FormErrorMessage>{form.errors.cpf}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='password'>
                                    {({ field, form }) => (
                                        <FormControl marginTop="24px" isInvalid={form.errors.password && form.touched.password}>
                                            <FormLabel
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color={textColor}
                                                display='flex'>
                                                Senha<Text color={brandStars}>*</Text>
                                            </FormLabel>
                                            <InputGroup size='md'>
                                                <Input
                                                    {...field}
                                                    fontSize='sm'
                                                    placeholder='Sua senha'
                                                    size='lg'
                                                    type={show ? "text" : "password"}
                                                    variant='auth'
                                                />
                                                <InputRightElement display='flex' alignItems='center' mt='4px'>
                                                    <Icon
                                                        color={textColorSecondary}
                                                        _hover={{ cursor: "pointer" }}
                                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                        onClick={handleClick}
                                                    />
                                                </InputRightElement>
                                            </InputGroup>
                                            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="24px"
                                        mb="24px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    fontSize='sm'
                                    variant='brand'
                                    fontWeight='500'
                                    w='100%'
                                    h='50'
                                    mb='24px'
                                    mt='24px'
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                >
                                    Entrar
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='start'
                        maxW='100%'
                        mt='0px'
                        mb={mbCount}
                    >
                        <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                            Já tem uma conta?
                            <NavLink to='/auth/sign-in'>
                                <Text
                                    color={textColorBrand}
                                    as='span'
                                    ms='5px'
                                    fontWeight='500'
                                >
                                    Acesse aqui
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <ToastContainer />
        </DefaultAuth>
    );
}

export default ExcludedAccount;
