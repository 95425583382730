export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
    {
        Header: "NOME",
        accessor: "name",
    },
    {
        Header: "QUANTIDADE",
        accessor: "qtd",
    },
    {
        Header: "VALOR",
        accessor: "value",
    },
    {
        Header: "REMUNERAÇÃO",
        accessor: "percentage_of_remuneration",
    },
    {
        Header: "CRIADO EM",
        accessor: "created_at",
    },
    {
        Header: "SITUAÇÃO",
        accessor: "blocked_at",
    },
    {
        Header: "AÇÕES",
        accessor: "actions",
    },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
