/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
  Box,
  // SimpleGrid,
} from "@chakra-ui/react";
import ColumnsTable from "views/admin/leiloes/components/ColumnsTable";
import {
columnsDataColumns,
} from "views/admin/leiloes/variables/columnsData";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import { ListAuctions } from "services/auctions";
// import { ListTokensForType } from "services/tokens";

export default function Leiloes() {
  const { user } = useAuthContext();

  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState('all');
  const [orderBy, setOrderBy] = useState('');

  useEffect(() => {
      const timeOutId = setTimeout(() => {
          getList();
      }, 500);
      return () => clearTimeout(timeOutId);
  }, [currentPage, type, orderBy]);

  const getList = async () => {
      const response = await ListAuctions(type, orderBy, undefined, undefined, currentPage, user?.token);
      setList(response?.result?.list || []);
      setTotalPages(response?.result?.qtdPages || 1);
  };

// Chakra Color Mode
return (
  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={list}
          getList={getList}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          type={type}
          setType={setType}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
      />
    {/* <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 2 }}
      spacing={{ base: "20px", xl: "20px" }}>
      <DevelopmentTable
        columnsData={columnsDataDevelopment}
        tableData={tableDataDevelopment}
      />
      <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
      <ColumnsTable
        columnsData={columnsDataColumns}
        tableData={tableDataColumns}
      />
      <ComplexTable
        columnsData={columnsDataComplex}
        tableData={tableDataComplex}
      />
    </SimpleGrid> */}
  </Box>
);
}
