/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  // SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import Card from "components/card/Card";
import { ListConfigurations } from "services/configurations";
import { toast, ToastContainer } from "react-toastify";
import { UpdateConfigurations } from "services/configurations";

export default function Config() {
  const { user } = useAuthContext();

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [list, setList] = useState([]);
  const [maximumTimeAuctions, setMaximumTimeAuctions] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
      const response = await ListConfigurations(user?.token);
      setMaximumTimeAuctions(response?.result?.configuration?.maximum_time_auctions || '');
  };

  const onSaveConfig = async () => {
    try {
      setIsLoading(true);
      const response = await UpdateConfigurations(Number(maximumTimeAuctions), user?.token);
      setIsLoading(false);
      if (response?.status === 200 || response?.status === 201) {
          toast(response?.message || 'Configurações salvas com sucesso!');
          getList();
      } else {
        toast('Ocorreu um erro salvar configurações!');
      }
    } catch (error) {
      toast('Ocorreu um erro salvar configurações!');
    }
  }

return (
  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <Card
      direction='column'
      w='100%'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <FormControl sx={{ width: '50%' }}>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'
            >
                Tempo máximo dos leilões
            </FormLabel>
            <Input
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                placeholder='Tempo máximo dos leilões'
                fontWeight='500'
                size='lg'
                mb="20px"
                value={maximumTimeAuctions}
                onChange={(txt) => {
                  const value = txt.target.value;
                  setMaximumTimeAuctions(value);
                }}
            />
        </FormControl>
        <Button
            variant='brand'
            alignSelf="flex-end"
            w="150px"
            marginTop="24px"
            onClick={onSaveConfig}
            isLoading={isLoading}
        >
            Salvar
        </Button>
    </Card>
    <ToastContainer />
  </Box>
);
}
