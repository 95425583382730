export const columnsDataColumns = [
  {
      Header: "NOME DO USUÁRIO",
      accessor: "user_name",
  },
  {
      Header: "DESCRIÇÃO",
      accessor: "description",
  },
  {
      Header: "VALOR",
      accessor: "value",
  },
  {
      Header: "CRIADO EM",
      accessor: "created_at",
  },
  {
      Header: "AÇÕES",
      accessor: "actions",
  },
];
