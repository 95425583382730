// Chakra imports
import {
//   Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Icon,
//   Icon,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
//   InputGroup,
//   InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from 'yup';
// Assets
// import { MdUpload } from "react-icons/md";
// import Dropzone from "views/admin/profile/components/Dropzone";
import { useAuth } from "hooks/use-auth";
import { useAuthContext } from "contexts/SidebarContext";
import { maskCPF } from "utils/masks";
import { EditUser } from "services/users";
import { MaskCNPJ } from "utils/masks";
import { maskPhone } from "utils/masks";
import { MaskCep } from "utils/masks";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { ListUsers } from "services/users";

export default function Upload(props) {
  const { used, total, ...rest } = props;
  const auth = useAuth();
  const { user } = useAuthContext();

  const [typeUser, setTypeUser] = useState('cpf');
  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const textColorSecondary = "gray.400";
//   const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
//   const brandColor = useColorModeValue("brand.500", "white");
//   const textColorSecondary = "gray.400";
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        document: Yup.string().required('Documento é obrigatório'),
        telephone: Yup.string().required('Telefone é obrigatório'),
        cep: Yup.string().required('CEP é obrigatório'),
        address: Yup.string().required('Endereço é obrigatório'),
        city: Yup.string().required('Cidade é obrigatória'),
        neighborhood: Yup.string().required('Bairro é obrigatório'),
        district: Yup.string().required('Estado é obrigatório'),
        numberAddress: Yup.string().notRequired(),
        birthDate: Yup.string().required('Data de nascimento é obrigatária'),
        email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
        password: Yup.string(),
    });
  return (
    <Card {...rest} mb='20px' align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        {/* <Dropzone
          w={{ base: "100%", "2xl": "268px" }}
          me='36px'
          maxH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          minH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          content={
            <Box>
              <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
              <Flex justify='center' mx='auto' mb='12px'>
                <Text fontSize='xl' fontWeight='700' color={brandColor}>
                  Upload Files
                </Text>
              </Flex>
              <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                PNG, JPG and GIF files are allowed
              </Text>
            </Box>
          }
        /> */}
        <Flex direction='column' pe='44px'>
            <Formik
                initialValues={{ name: user?.user?.name || '', document: user?.user?.document || '', telephone: user?.user?.telephone || '', cep: user?.user?.cep || '', address: user?.user?.address || '', city: user?.user?.city || '', neighborhood: user?.user?.neighborhood || '', district: user?.user?.district || '', numberAddress: user?.user?.number_address || '', birthDate: user?.user?.birth_date || '', email: user?.user?.email || '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={async (values, helpers) => {
                    try {
                        const response = await EditUser(
                            typeUser === 'cpf' ? 'PF' : 'PJ',
                            values.name,
                            values.document,
                            values.telephone,
                            values.cep,
                            values.address,
                            values.city,
                            values.neighborhood,
                            values.district,
                            values.numberAddress,
                            values.birthDate,
                            values.email,
                            values.password,
                            user?.user?.id,
                            user?.token,
                        );
                        if (response?.status === 200 || response?.status === 201) {
                            helpers.setSubmitting(false);
                            const responseList = await ListUsers(user?.user?.id, '', 1, null, user?.token);
                            if (responseList?.result?.list?.length > 0) {
                                await auth.updateDataUser({ ...user, user: { ...responseList.result.list[0], typeUser: 'users' } });
                            } else {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'Seus dados foram atualizados mas seu usuário não foi encontrado' });
                                helpers.setSubmitting(false);
                            }
                        } else {
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: response?.message });
                            helpers.setSubmitting(false);
                        }
                    } catch (err) {
                        helpers.setStatus({ success: false });
                        helpers.setErrors({ submit: err.message });
                        helpers.setSubmitting(false);
                    }
                }}
            >
                {(props) => (
                    <Form style={{ display: 'flex', flexDirection: 'column' }}>
                        <RadioGroup
                            onChange={(nextValue) => {
                                setTypeUser(nextValue);
                                if (nextValue === 'cpf') {
                                    props.setValues({
                                        name: '',
                                        cpf: '',
                                        telephone: '',
                                        cep: '',
                                        address: '',
                                        email: '',
                                        password: ''
                                    });
                                } else {
                                    props.setValues({
                                        nameCompany: '',
                                        cnpj: '',
                                        telephone: '',
                                        cep: '',
                                        address: '',
                                        email: '',
                                        password: ''
                                    });
                                }
                            }}
                            value={typeUser}
                            mb="24px"
                        >
                            <Stack direction='row'>
                                <Radio value='cpf'>CPF</Radio>
                                <Radio value='cnpj'>CNPJ</Radio>
                            </Stack>
                        </RadioGroup>
                        <Grid templateColumns='repeat(4, 1fr)' gap='16px'>
                            <Field name='name'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.name && form.touched.name}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            {typeUser === 'cpf' ? 'Nome' : 'Nome empresarial'}<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Seu nome'
                                            fontWeight='500'
                                            size='lg'
                                        />
                                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name="document">
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.document && form.touched.document}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            {typeUser === 'cpf' ? 'CPF' : 'CNPJ'}<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Seu documento'
                                            fontWeight='500'
                                            size='lg'
                                            disabled
                                            onChange={(txt) => {
                                                if (typeUser === 'cpf') {
                                                    const formatCPF = maskCPF(txt.target.value);
                                                    if (formatCPF.length <= 14) {
                                                        props.handleChange({
                                                            target: {
                                                                name: 'document',
                                                                value: formatCPF,
                                                            }
                                                        });
                                                    }
                                                } else {
                                                    const formatCNPJ = MaskCNPJ(txt.target.value);
                                                    props.handleChange({
                                                        target: {
                                                            name: 'document',
                                                            value: formatCNPJ,
                                                        }
                                                    });
                                                }
                                            }}
                                        />
                                        <FormErrorMessage>{form.errors.document}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='telephone'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.telephone && form.touched.telephone}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Telefone<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Seu telefone'
                                            fontWeight='500'
                                            size='lg'
                                            onChange={(txt) => {
                                                const formatPhone = maskPhone(txt.target.value);
                                                props.handleChange({
                                                    target: {
                                                        name: "telephone",
                                                        value: formatPhone,
                                                    }
                                                });
                                            }}
                                        />
                                        <FormErrorMessage>{form.errors.telephone}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='cep'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.cep && form.touched.cep}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            CEP<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Seu CEP'
                                            fontWeight='500'
                                            size='lg'
                                            onChange={(txt) => {
                                                const formatCep = MaskCep(txt.target.value);
                                                props.handleChange({
                                                    target: {
                                                        name: "cep",
                                                        value: formatCep,
                                                    }
                                                });
                                            }}
                                        />
                                        <FormErrorMessage>{form.errors.cep}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='address'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.address && form.touched.address}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Endereço<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Seu endereço'
                                            fontWeight='500'
                                            size='lg'
                                        />
                                        <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='city'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.city && form.touched.city}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Cidade<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Sua cidade'
                                            fontWeight='500'
                                            size='lg'
                                        />
                                        <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='neighborhood'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.neighborhood && form.touched.neighborhood}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Bairro<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Seu bairro'
                                            fontWeight='500'
                                            size='lg'
                                        />
                                        <FormErrorMessage>{form.errors.neighborhood}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='district'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.district && form.touched.district}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Estado<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Seu estado'
                                            fontWeight='500'
                                            size='lg'
                                        />
                                        <FormErrorMessage>{form.errors.district}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='numberAddress'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.numberAddress && form.touched.numberAddress}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Número
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Seu número'
                                            fontWeight='500'
                                            size='lg'
                                        />
                                        <FormErrorMessage>{form.errors.numberAddress}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='birthDate'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.birthDate && form.touched.birthDate}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            Data de nascimento<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Sua data de nascimento'
                                            fontWeight='500'
                                            size='lg'
                                            type="date"
                                        />
                                        <FormErrorMessage>{form.errors.birthDate}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='email'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'
                                        >
                                            E-mail<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <Input
                                            {...field}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            type='email'
                                            placeholder='Seu e-mail'
                                            fontWeight='500'
                                            size='lg'
                                        />
                                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='password' style={{ gridColumn: 'span 2' }}>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.password && form.touched.password}>
                                        <FormLabel
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            display='flex'>
                                            Senha<Text color={brandStars}>*</Text>
                                        </FormLabel>
                                        <InputGroup size='md'>
                                            <Input
                                                {...field}
                                                fontSize='sm'
                                                placeholder='Sua senha'
                                                size='lg'
                                                type={show ? "text" : "password"}
                                                variant='auth'
                                            />
                                            <InputRightElement display='flex' alignItems='center' mt='4px'>
                                                <Icon
                                                    color={textColorSecondary}
                                                    _hover={{ cursor: "pointer" }}
                                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                    onClick={handleClick}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Grid>
                        {props.errors.submit && (
                            <Text
                                color={props.status.success ? 'green.500' : 'red.500'}
                                fontSize='small'
                                fontWeight='400'
                                marginTop="20px"
                            >
                                {props.errors.submit}
                            </Text>
                        )}
                        <Button
                            variant='brand'
                            isLoading={props.isSubmitting}
                            type="submit"
                            alignSelf="flex-end"
                            w="150px"
                            marginTop="24px"
                        >
                            Atualizar dados
                        </Button>
                    </Form>
                )}
            </Formik>
        </Flex>
      </Flex>
    </Card>
  );
}
