import { api } from './api';

export async function ListConfigurations(token) {
    try {
        const response = await api.get('/configurations', {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function UpdateConfigurations(maximumTimeAuctions, token) {
  try {
      const response = await api.put('/configurations', { maximumTimeAuctions }, {
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}
