/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
//   Avatar,
  Box,
//   Flex,
//   FormLabel,
  Icon,
//   Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// import Usa from "assets/img/dashboards/usa.png";
// Custom components
// import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { useAuthContext } from "contexts/SidebarContext";
import React, { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import {
//   MdAddTask,
  MdAttachMoney,
  MdBarChart,
//   MdFileCopy,
  MdGeneratingTokens,
  MdMoneyOff,
} from "react-icons/md";
import {
    TbSignatureOff,
} from "react-icons/tb";
import { DashboardTokens } from "services/dashboard";
import { toPrice } from "utils/masks";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import PieCard from "views/admin/default/components/PieCard";
// import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import {
//   columnsDataCheck,
//   columnsDataComplex,
// } from "views/admin/default/variables/columnsData";
// import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
// import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

export default function UserReports() {
    const { user } = useAuthContext();

    const [result, setResult] = useState(null);

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        const response = await DashboardTokens(user?.token);
        setResult(response?.result || null);
    };

    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

    if (!result) {
        return null;
    }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Ganhos totais'
          value={`R$ ${toPrice(result?.details?.allValue || 0, 2)}`}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Ganhos do mês'
          value={`R$ ${toPrice(result?.details?.monthValue || 0, 2)}`}
        />
        {/* <MiniStatistics growth='+23%' name='Vendas' value='R$ 574,34' /> */}
        {/* <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        /> */}
        {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='New Tasks'
          value='154'
        /> */}
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdGeneratingTokens} color={brandColor} />
              }
            />
          }
          name='Tokens vendidos'
          value={result?.details?.qtdTokensSales || 0}
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={TbSignatureOff} color="red.600" />
              }
            />
          }
          name='Pedidos não assinados'
          allLabels={[
            {
                value: result?.details?.noSignature?.orders || 0,
                label: 'Quantidade de pedidos não assinados',
            },
            {
                value: result?.details?.noSignature?.qtd || 0,
                label: 'Quantidade total de tokens dos pedidos não assinados',
            },
            {
                format: true,
                value: result?.details?.noSignature?.value?.all_value || 0,
                label: 'Valor total dos pedidos não assinados',
            },
          ]}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdMoneyOff} color="red.600" />
              }
            />
          }
          name='Pedidos não pagos'
          allLabels={[
            {
                value: result?.details?.noPaid?.orders || 0,
                label: 'Quantidade de pedidos não pagos',
            },
            {
                value: result?.details?.noPaid?.qtd || 0,
                label: 'Quantidade total de tokens dos pedidos não pagos',
            },
            {
                format: true,
                value: result?.details?.noPaid?.value?.all_value || 0,
                label: 'Valor total dos pedidos não pagos',
            },
          ]}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={IoMdCloseCircle} color="red.600" />
              }
            />
          }
          name='Pedidos cancelados pelo cliente'
          allLabels={[
            {
                value: result?.details?.abort?.orders || 0,
                label: 'Quantidade de pedidos cancelados pelo cliente',
            },
            {
                value: result?.details?.abort?.qtd || 0,
                label: 'Quantidade total de tokens dos pedidos cancelados pelo cliente',
            },
            {
                format: true,
                value: result?.details?.abort?.value?.all_value || 0,
                label: 'Valor total dos pedidos cancelados pelo cliente',
            },
          ]}
        />
        </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent result={result} />
        <WeeklyRevenue result={result} />
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
