export const columnsDataColumns = [
  {
      Header: "IMAGEM",
      accessor: "file",
  },
  {
      Header: "POSIÇÃO",
      accessor: "position",
  },
  {
      Header: "CRIADO EM",
      accessor: "created_at",
  },
  {
      Header: "AÇÕES",
      accessor: "actions",
  },
];
