/* eslint-disable jsx-a11y/iframe-has-title */
// chakra imports
import { Icon, Flex, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Alert, AlertIcon, ModalFooter, Button, Image, Checkbox, useColorModeValue } from "@chakra-ui/react";
import { useAuthContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import { MdCopyAll } from "react-icons/md";
import { AutentiqueCreate } from "services/autentique";
import { VerifyPix } from "services/pagseguro";
import { GeneratePix } from "services/pagseguro";

export function ModalOrder({ isOpen, setIsOpen, infoToken, setInfoToken, getList, isLoading, setIsLoading, step, setStep }) {
    const { user } = useAuthContext();

    const [error, setError] = useState(null);
    const [isChecked, setIsChecked] = useState(false);

    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");

    const onClose = () => {
        setIsOpen(false);
        setInfoToken(null);
        setError(null);
        setStep(1);
    };

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    const handleClickSignature = async () => {
        if (!isChecked) {
            return setError('Aceite os termos antes de prosseguir.');
        }
        setIsLoading(true);
        const response = await AutentiqueCreate(infoToken?.id, user?.user?.id, infoToken?.qtd, infoToken?.pdf, user?.token);
        if (response?.result?.contract?.id) {
            const responsePix = await GeneratePix(response?.result?.contract?.order?.id || infoToken?.id, user?.token);
            if (responsePix?.result?.order?.id) {
                setIsLoading(false);
                setError(null);
                setInfoToken({ ...infoToken, contract: response?.result?.contract, order: responsePix?.result?.order });
                getList();
                setStep(2);
            } else {
                setError(responsePix?.message || 'Erro ao gerar pix');
                setIsLoading(false);
            }
        } else {
            setError(response?.message || 'Ocorreu um erro ao gerar o pagamento. Entre em contato com o suporte.');
            setIsLoading(false);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert('Conteúdo copiado para a área de transferência!');
            })
            .catch((error) => {
                console.error('Erro ao copiar o conteúdo:', error);
            });
    };

    const handleClickPay = async () => {
        setIsLoading(true);
        const response = await VerifyPix(infoToken?.order?.id_order || infoToken?.id_order, user?.token);
        setIsLoading(false);
        if (!response?.result?.order?.status || response?.result?.order?.status === 'NO_PAID') {
            return setError('O pix ainda não foi pago. Efetue o pagamento para adquirir seu token.');
        }
        if (response?.result?.order?.status === 'PAID') {
            alert('Token adquirido! Confira a aba Meus Tokens.');
            getList();
            return onClose();
        }
    };

    const renderQrCode = () => {
        let QrCodes = [];
        if (infoToken?.log) {
            QrCodes = JSON.parse(infoToken?.log)?.qr_codes || infoToken?.qrcode;
        }
        if (infoToken?.order) {
            QrCodes = infoToken?.order?.qrcode || infoToken?.order?.qr_codes;
        }
        if (!QrCodes?.length) {
            return <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>Nenhum QRCODE foi gerado.</Text>;
        }
        return (
            <>
                <Image alignSelf="center" height={200} width={200} src={typeof QrCodes === 'string' ? QrCodes : QrCodes[0]?.links?.find((v) => v.media === 'image/png')?.href} />
                <Flex display="flex" alignItems="center" justifyContent="center">
                    <Icon cursor="pointer" as={MdCopyAll} width='30px' height='30px' color="blue.500" onClick={() => handleCopy(JSON.parse(infoToken?.order?.log || infoToken?.log)?.payment?.pix?.qrCode || QrCodes[0]?.text)} />
                    <Text color='secondaryGray.700' mt="10px" fontSize='8px' fontWeight='400' wordBreak="break-all">{JSON.parse(infoToken?.order?.log || infoToken?.log)?.payment?.pix?.qrCode || QrCodes[0]?.text}</Text>
                </Flex>
            </>
        );
    };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered scrollBehavior="inside" size="lg">
    <ModalOverlay />
    {step === 1 ? (
        <ModalContent>
            <ModalHeader>
                Contrato para compra do token
                <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>Leia o contrato abaixo e aceite as condições</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody justifyContent="center" alignItems="center" display="flex" flexDirection="column">
                <iframe height={400} width="100%" src={infoToken?.pdf} />
                <Flex
                    justifyContent='center'
                    maxW='100%'
                    mt='0px'
                    marginTop="10px"
                    display="flex"
                    alignSelf="flex-start"
                >
                    <Checkbox
                        colorScheme='brandScheme'
                        me='10px'
                        isChecked={isChecked}
                        onChange={handleChange}
                    />
                    <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                        Eu concordo e aceito os termos do contrato
                    </Text>
                </Flex>
                {error && (
                    <Alert status="info" variant="left-accent" mt="20px">
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
            </ModalBody>
    
            <ModalFooter>
                <Button isLoading={isLoading} variant="brand" onClick={handleClickSignature}>Ir para pagamento</Button>
            </ModalFooter>
        </ModalContent>
    ) : (
        <ModalContent>
            <ModalHeader>
                Pagamento para compra do token
                <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>Escaneie o QRCODE abaixo ou copie e cole o código para efetuar o pagamento.</Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody justifyContent="center" alignItems="center" display="flex" flexDirection="column">
                {renderQrCode()}
                {error && (
                    <Alert status="info" variant="left-accent" mt="20px">
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
            </ModalBody>
    
            <ModalFooter>
                <Button isLoading={isLoading} variant="brand" onClick={handleClickPay}>Já paguei, adquirir o token</Button>
            </ModalFooter>
        </ModalContent>
    )}
    </Modal>
  );
}
