/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import HistoryItem from "views/admin/tokensSale/components/HistoryItem";
import Card from "components/card/Card.js";

// Assets
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { ListTokens } from "services/tokens";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ToastContainer, toast } from "react-toastify";
import { PagseguroAvulso } from "services/pagseguro";
import Autocomplete from "react-autocomplete";
import { ListUsers } from "services/users";
import { ListTokensForType } from "services/tokens";
import { DeleteHash } from "services/tokens";
import { ListUniqueTokens } from "services/tokens";
import { lightFormat } from "date-fns";
import { toPrice } from "utils/masks";

export default function Tokens() {
    const { user } = useAuthContext();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const bgItem = useColorModeValue(
      { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
      { bg: "navy.700", boxShadow: "unset" }
    );

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [listOrder, setListOrder] = useState([]);
    const [totalPagesOrder, setTotalPagesOrder] = useState(1);
    const [currentPageOrder, setCurrentPageOrder] = useState(1);
    const [searchTxtOrder, setSearchTxtOrder] = useState('');
    const [listHash, setListHash] = useState([]);
    // const [totalPagesHash, setTotalPagesHash] = useState(1);
    const [currentPageHash, setCurrentPageHash] = useState(1);
    const [searchTxtHash, setSearchTxtHash] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [infoToken, setInfoToken] = useState(null);
    const [infoUser, setInfoUser] = useState(null);
    const [frame, setFrame] = useState(1);
    const [usersAutoComplete, setUsersAutoComplete] = useState('');
    const [usersSearch, setUsersSearch] = useState('');
    const [listUsers, setListUsers] = useState([]);
    const [tokensAutoComplete, setTokensAutoComplete] = useState('');
    const [tokensSearch, setTokensSearch] = useState('');
    const [listTokens, setListTokens] = useState([]);
    const [qtd, setQtd] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [modalDeleteHash, setModalDeleteHash] = useState(false);
    const [itemDeleteHash, setItemDeleteHash] = useState(null);

    useEffect(() => {
        if (frame === 1) {
            const timeOutId = setTimeout(() => {
                getListForUser();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [currentPage, searchTxt, frame]);

    useEffect(() => {
        if (frame === 2) {
            const timeOutId = setTimeout(() => {
                getListForOrder();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [currentPageOrder, searchTxtOrder, frame]);

    useEffect(() => {
        if (frame === 3) {
            const timeOutId = setTimeout(() => {
                getListUnique();
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [currentPageHash, searchTxtHash, frame]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListUsers();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [usersSearch]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListTokens();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [tokensSearch]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePageChangeOrder = (pageNumber) => {
        setCurrentPageOrder(pageNumber);
    };

    // const handlePageChangeHash = (pageNumber) => {
    //     setCurrentPageHash(pageNumber);
    // };

    const getListForUser = async () => {
        const response = await ListTokens(null, searchTxt, currentPage, user?.user?.typeUser, user?.token);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
    };

    const getListForOrder = async () => {
        const response = await ListTokens(infoUser?.user_id, searchTxtOrder, currentPageOrder, user?.user?.typeUser, user?.token);
        setListOrder(response?.result?.list || []);
        setTotalPagesOrder(response?.result?.qtdPages || 1);
    };

    const getListUnique = async () => {
        const response = await ListUniqueTokens(infoToken?.id, searchTxtHash, currentPageHash, user?.token);
        setListHash(response?.result?.list || []);
        // setTotalPagesHash(response?.result?.qtdPages || 1);
    };

    const getListUsers = async () => {
        const response = await ListUsers(null, usersSearch, 1, 5, user?.token);
        setListUsers(response?.result?.list || []);
    };

    const getListTokens = async () => {
        const response = await ListTokensForType(null, user?.user?.id, tokensSearch, 1, 'adm', 5, user?.token);
        setListTokens(response?.result?.list || []);
    };

    const renderStatus = (status) => {
        if (status === 'NO_SIGNATURE') {
            return 'Não assinado';
        }
        if (status === 'NO_PAID') {
            return 'Não pago';
        }
        if (status === 'PAID') {
            return 'Pago';
        }
        if (status === 'AUTHORIZED') {
            return 'Pagamento em processamento';
        }
        if (status === 'CANCELED') {
            return 'Pagamento cancelado';
        }
        if (status === 'ABORT') {
            return 'Pedido cancelado pelo cliente';
        }
        return '';
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const onCloseHash = () => {
        setModalDeleteHash(false);
        setItemDeleteHash(null);
    };
    
    const handleClickToken = async () => {
        if (usersAutoComplete && tokensAutoComplete && qtd) {
            try {
                setIsLoading(true);
                const response = await PagseguroAvulso(
                    Number(tokensAutoComplete),
                    Number(usersAutoComplete),
                    Number(qtd),
                    user?.token,
                );
                setIsLoading(false);
                if (response?.status === 200 || response?.status === 201) {
                    toast(response?.message || 'Token adicionado com sucesso!');
                    setList([]);
                    setTokensAutoComplete('');
                    setUsersAutoComplete('');
                    setQtd('');
                    setTokensSearch('');
                    setUsersSearch('');
                    onClose();
                    getListForUser();
                } else {
                    toast(response?.message);
                }
            } catch (err) {
                setIsLoading(false);
                toast(err.message);
            }
        } else {
            toast('Preencha todos os campos');
        }
    };

    const ApplyDeleteHash = async () => {
        try {
            setIsLoading(true);
            const response = await DeleteHash(
                itemDeleteHash?.id,
                user?.token,
            );
            setIsLoading(false);
            if (response?.status === 200 || response?.status === 201) {
                toast(response?.message || 'Hash removida com sucesso!');
                onCloseHash();
                getListUnique();
            } else {
                toast(response?.message);
            }
        } catch (err) {
            setIsLoading(false);
            toast(err.message);
        }
    }

    const renderFrames = () => {
        if (frame === 1) {
            return (
                <Card p='0px 0px 20px 0px'>
                    <Flex justifyContent="space-between" padding="20px 20px 20px 20px">
                        <SearchBar
                            placeholder="Buscar por usuário"
                            value={searchTxt}
                            setValue={setSearchTxt}
                        />
                        <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant='brand'>
                            Adicionar
                        </Button>
                    </Flex>
                    {(list || []).map((v) => (
                        <HistoryItem
                            name={v.user_name}
                            author={`Situação: ${renderStatus(v.status)}`}
                            info={`Quantidade de unidades adquiridas: ${v.qtd_total}`}
                            complement={''}
                            date={v.created_at}
                            image=""
                            price={v.total_value}
                            key={v.id}
                            onClick={() => {
                                setInfoUser(v);
                                setFrame(2);
                            }}
                            handleClickContinueOrder={() => null}
                            getList={getListForUser}
                            item={v}
                        />
                    ))}
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Card>
            );
        }
        if (frame === 2) {
            return (
                <Card p='0px 0px 20px 0px'>
                    <Flex justifyContent="space-between" padding="20px 20px 0px 20px" mb="20px">
                        <SearchBar
                            placeholder="Buscar token"
                            value={searchTxtOrder}
                            setValue={setSearchTxtOrder}
                        />
                        <Button
                            onClick={() => {
                                setFrame(1);
                                setList([]);
                                setListOrder([]);
                                setCurrentPageOrder(1);
                                setTotalPagesOrder(1);
                                setSearchTxtOrder('');
                            }}
                            w='200px'
                            h='44px'
                            variant='brand'
                        >
                            Ver todos os usuários
                        </Button>
                    </Flex>
                    {(listOrder || []).map((v) => (
                        <HistoryItem
                            name={v.token_name}
                            author={`Situação: ${renderStatus(v.status)}`}
                            info={`Total de tokens adquiridos: ${v.qtd_total}`}
                            complement={`Adquirido por: ${v.user_name || ''}`}
                            date={v.created_at}
                            image={v.token_logo}
                            price={v.total_value}
                            key={v.id}
                            onClick={() => {
                                setInfoToken(v);
                                setFrame(3);
                            }}
                            handleClickContinueOrder={() => null}
                            getList={getListForUser}
                            item={v}
                        />
                    ))}
                    <Pagination
                        currentPage={currentPageOrder}
                        totalPages={totalPagesOrder}
                        onPageChange={handlePageChangeOrder}
                    />
                </Card>
            );
        }
        if (frame === 3) {
            return (
                <Card p='0px 0px 20px 0px'>
                <Flex justifyContent="space-between" padding="20px 20px 0px 20px" mb="20px">
                    <SearchBar
                        placeholder="Buscar hash"
                        value={searchTxtHash}
                        setValue={setSearchTxtHash}
                    />
                    <Button
                        onClick={() => {
                            setFrame(2);
                            setList([]);
                            setListOrder([]);
                            setListHash([]);
                            setCurrentPageHash(1);
                            // setTotalPagesHash(1);
                            setSearchTxtHash('');
                        }}
                        w='200px'
                        h='44px'
                        variant='brand'
                    >
                        Ver todos os tokens
                    </Button>
                </Flex>
                <Flex
                    direction='flex'
                    justifyContent="space-between"
                    w="90%"
                    alignSelf="center"
                    mb="10px"
                >
                    {["DATA DE COMPRA", "VALOR PAGO", "QTD TOKENS", "TIPO", "EXCLUIR"]?.map((v, i) => (
                        <Text
                            color={textColor}
                            fontSize={{
                                base: "md",
                            }}
                            fontWeight='bold'
                            key={v}
                            w="19%"
                        >
                            {v}
                        </Text>
                    ))}
                </Flex>
                {(listHash || []).map((v) => (
                    <Card
                        _hover={bgItem}
                        bg='transparent'
                        boxShadow='unset'
                        px='0px'
                        // py='0px'
                        transition='0.2s linear'
                        style={{ cursor: 'pointer' }}
                        key={v.id}
                        w="90%"
                        alignSelf="center"
                    >
                        <Flex
                            direction='flex'
                            justifyContent="space-between"
                            w="100%"
                            alignSelf="center"
                        >
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: "md",
                                }}
                                fontWeight='bold'
                                w="19%"
                            >
                                {lightFormat(new Date(v.created_at), 'dd/MM/yyyy HH:mm')}
                            </Text>
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: "md",
                                }}
                                fontWeight='bold'
                                w="19%"
                            >
                                {`R$ ${toPrice(v.token_value_unity * v.token_qtd_order, 2)}`}
                            </Text>
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: "md",
                                }}
                                fontWeight='bold'
                                w="19%"
                            >
                                {v.token_qtd_order}
                            </Text>
                            <Text
                                color={textColor}
                                fontSize={{
                                    base: "md",
                                }}
                                fontWeight='bold'
                                w="19%"
                            >
                                {v.transactionId ? 'Pago' : 'Adicionado'}
                            </Text>
                            <Button
                              bg="red.600"
                              color="white"
                              onClick={() => {
                                setModalDeleteHash(true);
                                setItemDeleteHash(v);
                              }}
                              isLoading={isLoading}
                              w="19%"
                            >
                              Excluir token
                            </Button>
                        </Flex>
                    </Card>
                ))}
                {/* <Pagination
                    currentPage={currentPageHash}
                    totalPages={totalPagesHash}
                    onPageChange={handlePageChangeHash}
                    limit={30}
                /> */}
                </Card>
            );
        }
    }

  // Chakra Color Mode
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
            {renderFrames()}
        </Flex>
        <Modal isOpen={modalDeleteHash} onClose={onCloseHash} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    Remover hash
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Text color='secondaryGray.700' fontSize='medium' fontWeight='400'>Tem certeza que deseja remover permanentemente a hash selecionada?</Text>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant='outline'
                        w="150px"
                        marginRight="10px"
                        onClick={onCloseHash}
                    >
                        Fechar
                    </Button>
                    <Button
                        bg="red.600"
                        color="white"
                        w="150px"
                        onClick={ApplyDeleteHash}
                        isLoading={isLoading}
                    >
                        REMOVER
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Adicionar token
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para adicionar um token ao usuário`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Flex style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <FormControl style={{ maxWidth: '50%', marginBottom: 16 }}>
                            <FormLabel
                                display='flex'
                                ms='4px'
                                fontSize='sm'
                                fontWeight='500'
                                color={textColor}
                                mb='8px'
                            >
                                Quantidade<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant='auth'
                                fontSize='sm'
                                ms={{ base: "0px", md: "0px" }}
                                placeholder='Quantidade'
                                type="number"
                                fontWeight='500'
                                size='lg'
                                value={qtd}
                                onChange={(txt) => {
                                    if (txt.target.value.length < 10) {
                                        setQtd(txt.target.value);
                                    }
                                }}
                            />
                        </FormControl>
                        <Grid templateColumns='repeat(2, 1fr)' gap='16px' mb="100px">
                            <FormControl>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    color={textColor}
                                    mb='8px'
                                >
                                    Usuário<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <Autocomplete
                                    getItemValue={(item) => item.id}
                                    items={listUsers}
                                    renderItem={(item, isHighlighted) =>
                                        <Text cursor="pointer" color={isHighlighted ? 'blue.500' : 'secondaryGray.700'} fontSize='sm' fontWeight='400' p="2px 5px 2px 5px">{item.name}</Text>
                                    }
                                    renderInput={(props) => (
                                        <Input
                                            {...props}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Selecione um usuário'
                                            fontWeight='500'
                                            size='lg'
                                            value={usersSearch}
                                            onChange={(e) => setUsersSearch(e.target.value)}
                                        />
                                    )}
                                    onSelect={(val, item) => {
                                        setUsersAutoComplete(val);
                                        setUsersSearch(item.name);
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel
                                    display='flex'
                                    ms='4px'
                                    fontSize='sm'
                                    fontWeight='500'
                                    color={textColor}
                                    mb='8px'
                                >
                                    Token<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <Autocomplete
                                    getItemValue={(item) => item.id}
                                    items={listTokens}
                                    renderItem={(item, isHighlighted) =>
                                        <Text cursor="pointer" color={isHighlighted ? 'blue.500' : 'secondaryGray.700'} fontSize='sm' fontWeight='400' p="2px 5px 2px 5px">{item.name}</Text>
                                    }
                                    renderInput={(props) => (
                                        <Input
                                            {...props}
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='Selecione um token'
                                            fontWeight='500'
                                            size='lg'
                                            value={tokensSearch}
                                            onChange={(e) => setTokensSearch(e.target.value)}
                                        />
                                    )}
                                    onSelect={(val, item) => {
                                        setTokensAutoComplete(val);
                                        setTokensSearch(item.name);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Button
                            variant='brand'
                            type="submit"
                            alignSelf="flex-end"
                            w="150px"
                            marginTop="24px"
                            onClick={handleClickToken}
                            isLoading={isLoading}
                        >
                            Adicionar token
                        </Button>
                    </Flex>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <ToastContainer />
    </Box>
  );
}
