import React, { useState } from "react";
// Chakra imports
import { Button, Flex,
    // Icon,
    Image, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
// import { FaEthereum } from "react-icons/fa";
import { lightFormat } from "date-fns";
import { toPrice } from "utils/masks";
import { CancelOrder } from "services/pagseguro";
import { useAuthContext } from "contexts/SidebarContext";

export default function NFT(props) {
  const { image, name, author, info, date, price, onClick, item, getList, handleClickContinueOrder } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );
  const textColorDate = useColorModeValue("secondaryGray.600", "white");
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleClickCancelOrder = async () => {
    try {
        setIsLoading(true);
        const response = await CancelOrder(item?.id, user?.token);
        setIsLoading(false);
        if (response?.status === 200) {
            getList();
        } else {
            alert(response?.message || 'Ocorreu um erro ao cancelar o pedido.');
        }
    } catch (error) {
        setIsLoading(false);
    }
  };

  return (
    <Card
      _hover={bgItem}
      bg='transparent'
      boxShadow='unset'
      px='24px'
      py='21px'
      transition='0.2s linear'
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <Flex direction={{ base: "column" }} justify='center'>
        <Flex position='relative' align='center'>
          {image ? <Image src={image} w='66px' h='66px' borderRadius='20px' me='16px' /> : null}
          <Flex
            direction='column'
            w={{ base: "70%", md: "100%" }}
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>
            <Text
              color={textColor}
              fontSize={{
                base: "md",
              }}
              mb='5px'
              fontWeight='bold'
              me='14px'>
              {name || ''}
            </Text>
            <Text
              color='secondaryGray.600'
              fontSize={{
                base: "sm",
              }}
              fontWeight='400'
              me='14px'>
              {author || ''}
            </Text>
            <Text
              color='secondaryGray.600'
              fontSize={{
                base: "sm",
              }}
              fontWeight='400'
              me='14px'>
              {info || ''}
            </Text>
          </Flex>
          <Flex flexDirection="column" me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>
            <Flex
                align='center'
                style={{ width: 200 }}
            >
                {/* <Icon as={FaEthereum} color={textColor} width='9px' me='7px' /> */}
                {price ? (
                    <>
                        <Text fontWeight='700' fontSize='md' color={textColor} mr="5px">R$</Text>
                        <Text fontWeight='700' fontSize='md' color={textColor} mr="10px">
                            {`${toPrice(price, 2)}`}
                        </Text>
                    </>
                ) : null}
                <Text ms='auto' fontWeight='700' fontSize='sm' color={textColorDate}>
                    {lightFormat(new Date(date), 'dd/MM/yyyy HH:mm')}
                </Text>
            </Flex>
            {['NO_PAID', 'NO_SIGNATURE'].includes(item?.status) ? <Button alignSelf="flex-start" mt="10px" variant="brand" onClick={() => handleClickContinueOrder(item)}>Continuar compra</Button> : null}
            {['NO_PAID', 'NO_SIGNATURE'].includes(item?.status) ? <Button isLoading={isLoading} alignSelf="flex-start" mt="10px" variant="outline" onClick={handleClickCancelOrder}>Cancelar compra</Button> : null}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
