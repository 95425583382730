import { api } from './api';

// type: 'deposit' | 'withdraw',
// status: 'pending' | 'canceled' | 'finish' | undefined,
export async function ListExtracts(type, status, idUser, searchTxt, page, token) {
    try {
        let baseURL = `/extracts?limit=10&type=${type}&searchTxt=${searchTxt}`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        if (status) {
            baseURL = `${baseURL}&status=${status}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function UpdateWithdraw(idWalletExtract, proofOfPayment, status, token) {
  try {
      const response = await api.patch(`/extracts/withdraw?idWalletExtract=${idWalletExtract}`, { proofOfPayment, status }, {
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}
