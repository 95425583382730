import { api } from './api';

export async function ListProof(idUser, page, token) {
    try {
        let baseURL = `/tokensProofOfPayment?limit=10`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (idUser) {
            baseURL = `${baseURL}&idUser=${idUser}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateProof(file, value, description, idUser, token) {
    try {
        const response = await api.post('/tokensProofOfPayment', { file, value, description, idUser }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditProof(file, value, description, idUser, idProofOfPayment, token) {
    try {
        const response = await api.put(`/tokensProofOfPayment?idProofOfPayment=${idProofOfPayment}`, { file, value, description, idUser }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteProof(idProofOfPayment, token) {
  try {
      const response = await api.delete(`/tokensProofOfPayment?idProofOfPayment=${idProofOfPayment}`, {
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
"ngrok-skip-browser-warning": "69420",
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}
