import { api } from './api';

export async function Signin(email, password) {
    try {
        const response = await api.patch('/users/token', { email, password }, {
            headers: {
                'Content-Type': 'application/json',
                "ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function SigninApp(document, password) {
  try {
      const response = await api.patch('/users/token', { document, password }, {
          headers: {
              'Content-Type': 'application/json',
              "ngrok-skip-browser-warning": "69420",
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}

export async function ApplyExcludedAccount(idUser) {
  try {
      const response = await api.delete(`/users?idUser=${idUser}`, {
          headers: {
              'Content-Type': 'application/json',
              "ngrok-skip-browser-warning": "69420",
          },
      });
      return response?.data;
  } catch (error) {
      const { response } = error;
      return response?.data;
  }
}

export async function RefreshToken(refresh, type) {
    try {
        const response = await api.patch('/users/refreshToken', { refresh, type }, {
            headers: {
                'Content-Type': 'application/json',
                "ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function Signup(body) {
    try {
        const response = await api.post('/users/register', body, {
            headers: {
                'Content-Type': 'application/json',
                "ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function Recover(email) {
    try {
        const response = await api.patch('/users/recoverPassword', { email }, {
            headers: {
                'Content-Type': 'application/json',
                "ngrok-skip-browser-warning": "69420",
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
