export const columnsDataColumns = [
    {
        Header: "NOME DO USUÁRIO",
        accessor: "user_name",
    },
    {
        Header: "CRIADO EM",
        accessor: "created_at",
    },
    {
        Header: "SITUAÇÃO",
        accessor: "status",
    },
    {
        Header: "AÇÕES",
        accessor: "actions",
    },
];
