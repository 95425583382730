/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
Flex,
FormControl,
FormErrorMessage,
FormLabel,
Grid,
Icon,
Image,
Input,
Modal,
ModalBody,
ModalCloseButton,
ModalContent,
ModalFooter,
ModalHeader,
ModalOverlay,
Table,
Tbody,
Td,
Text,
Th,
Thead,
Tr,
useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useMemo, useState } from "react";
import {
useGlobalFilter,
usePagination,
useSortBy,
useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
import Dropzone from "views/admin/profile/components/Dropzone";
import { MdDocumentScanner, MdEditDocument, MdFileCopy, MdFileOpen, MdGeneratingTokens } from "react-icons/md";
import { lightFormat } from "date-fns";
import { toPrice } from "utils/masks";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { UpdateWithdraw } from "services/extracts";
import { getNameWithExtensionFromUrl } from "utils/masks";
import { DeleteBanner } from "services/banners";
import { CreateBanner } from "services/banners";
import { EditBanner } from "services/banners";

export default function ColumnsTable(props) {
  const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt } = props;

  const { user } = useAuthContext();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const tableInstance = useTable(
      {
      columns,
      data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
  );

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const brandColor = useColorModeValue("brand.500", "white");
  // const bg = useColorModeValue("gray.100", "navy.700");
  // const borderColorArchive = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  const [isOpen, setIsOpen] = useState(false);
  const [infoRow, setInfoRow] = useState(null);
  const [banner, setBanner] = useState('');
  const [positionBanner, setPositionBanner] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
      setIsOpen(false);
      setInfoRow(null);
      setBanner('');
      setPositionBanner('');
  };

  const onClickEdit = (item) => {
    setBanner(item.file || '');
    setPositionBanner(item.position || '');
    setInfoRow(item);
    setIsOpen(true);
  };

  const onClickDelete = async (item) => {
      await DeleteBanner(item.id, user?.token);
      getList();
      onClose();
  };

  const onSaveBanner = async () => {
    try {
      setIsLoading(true);
      const response = !infoRow?.id ? await CreateBanner(positionBanner, banner, user?.token) : await EditBanner(positionBanner, banner, infoRow?.id, user?.token);
      setIsLoading(false);
      if (response?.status === 200 || response?.status === 201) {
          toast(response?.message || 'Banner salvo com sucesso!');
          onClose();
          getList();
      } else {
        toast('Ocorreu um erro salvar banner!');
      }
    } catch (error) {
      toast('Ocorreu um erro salvar banner!');
    }
  }

return (
  <Card
    direction='column'
    w='100%'
    px='0px'
    overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex justifyContent="flex-end" padding="0px 20px 0px 20px" mb="20px">
          <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant='brand'>
              Criar banner
          </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
          <ModalOverlay />
          <ModalContent>
              <ModalHeader>
                  {infoRow?.id ? 'Editar banner' : 'Criar banner'}
                  <Text color='secondaryGray.700' fontSize='xs' fontWeght='400'>{`Preencha as informações abaixo para ${infoRow?.id ? 'editar' : 'criar'} um banner`}</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody display="flex" flexDirection="column" alignItems="center">
                  <FormControl>
                      <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'
                      >
                          Posição do banner
                      </FormLabel>
                      <Input
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          placeholder='Posição'
                          fontWeight='500'
                          size='lg'
                          mb="20px"
                          value={positionBanner}
                          onChange={(txt) => {
                            const value = txt.target.value;
                            if (!isNaN(value) && Number(value) >= 0) {
                              setPositionBanner(value);
                            }
                          }}
                      />
                  </FormControl>
                  <Dropzone
                      minH="60px"
                      w="100%"
                      borderRadius="10px"
                      mb="24px"
                      onImageSelect={(file) => setBanner(file[0] || '')}
                      multiple={false}
                      accept="image/png, image/jpeg, image/jpg"
                      content={
                          <Box display="flex" flexDirection="column" gap="10px">
                              {banner.length > 0 ? (
                                  <Text color='blue.500' fontSize='xs' fontWeight='400'>{getNameWithExtensionFromUrl(String(banner))}</Text>
                              ) : (
                                  <>
                                      <Text color='blue.500' fontSize='xs' fontWeight='400'>Clique aqui para selecionar o BANNER.</Text>
                                      <Text color='secondaryGray.700' fontSize='10px' fontWeight='400'>Formatos aceitos: PNG, JPEG, JPG</Text>
                                  </>
                              )}
                          </Box>
                      }
                  />
                  {!banner.length ? null : (
                    <>
                      <Text color='blue.500' fontSize='xs' fontWeight='400' mb="10px">Proporção recomendada: 8:3</Text>
                      <Image src={banner} w="320px" h="120px" borderRadius="10px" sx={{ objectFit: 'contain', backgroundColor: '#222222' }} />
                    </>
                  )}
                  <Button
                      variant='brand'
                      alignSelf="flex-end"
                      w="150px"
                      marginTop="24px"
                      onClick={onSaveBanner}
                      isLoading={isLoading}
                  >
                      {infoRow?.id ? 'Editar banner' : 'Criar banner'}
                  </Button>
              </ModalBody>

              <ModalFooter>
              </ModalFooter>
          </ModalContent>
      </Modal>
    <Table {...getTableProps()} variant='simple' color='gray.500' mb='100px'>
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                pe='10px'
                key={index}
                borderColor={borderColor}>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                  {column.render("Header")}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, index) => {
                let data = "";
                if (cell.column.Header === "AÇÕES") {
                  return (
                      <Flex align='center' pl="30px" pt="5px">
                        <Menu row={cell.row.original} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
                      </Flex>
                  )
                } else if (cell.column.Header === "IMAGEM") {
                  data = (
                    <Flex align='center' gap={1}>
                      <Image src={cell.value} minH="24px" h="24px" w="64px" minW="64px" style={{ borderRadius: 3 }} />
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {getNameWithExtensionFromUrl(String(cell.value))}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "POSIÇÃO") {
                  data = (
                    <Flex align='center'>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    </Flex>
                  );
                } else if (cell.column.Header === "CRIADO EM") {
                  data = (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  );
                }
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    {data}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
      <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
      />
      <ToastContainer />
  </Card>
);
}
