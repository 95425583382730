// Chakra imports
import {
  Box,
//   Button,
  Flex,
//   Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React from "react";
// import { IoCheckmarkCircle } from "react-icons/io5";
// import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
// Assets
// import { RiArrowUpSFill } from "react-icons/ri";
import {
//   lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";

export default function TotalSpent(props) {
  const { result, ...rest } = props;

  // Chakra Color Mode

  const textColor = useColorModeValue("secondaryGray.900", "white");
//   const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
//   const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
//   const iconColor = useColorModeValue("brand.500", "white");
//   const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
//   const bgHover = useColorModeValue(
//     { bg: "secondaryGray.400" },
//     { bg: "whiteAlpha.50" }
//   );
//   const bgFocus = useColorModeValue(
//     { bg: "secondaryGray.300" },
//     { bg: "whiteAlpha.100" }
//   );
  return (
    <Card
      justifyContent='center'
      align='center'
      direction='column'
      w='100%'
      mb='0px'
      {...rest}>
      <Flex justify='space-between' ps='0px' pe='20px' pt='5px'>
        <Flex align='center' w='100%'>
            <Text
            me='auto'
            color={textColor}
            fontSize='xl'
            fontWeight='700'
            lineHeight='100%'>
            Tokens mais vendidos
            </Text>
        </Flex>
      </Flex>
        <Text
        me='auto'
        color={textColor}
        fontSize='sm'
        fontWeight='700'
        marginTop="10px"
        lineHeight='100%'>
        Últimos 6 meses
        </Text>
      <Flex w='100%' flexDirection={{ base: "column", lg: "row" }}>
        {/* <Flex flexDirection='column' me='20px' mt='28px'>
          <Text
            color={textColor}
            fontSize='34px'
            textAlign='start'
            fontWeight='700'
            lineHeight='100%'>
            $37.5K
          </Text>
          <Flex align='center' mb='20px'>
            <Text
              color='secondaryGray.600'
              fontSize='sm'
              fontWeight='500'
              mt='4px'
              me='12px'>
              Total gasto
            </Text>
            <Flex align='center'>
              <Icon as={RiArrowUpSFill} color='green.500' me='2px' mt='2px' />
              <Text color='green.500' fontSize='sm' fontWeight='700'>
                +2.45%
              </Text>
            </Flex>
          </Flex>

          <Flex align='center'>
            <Icon as={IoCheckmarkCircle} color='green.500' me='4px' />
            <Text color='green.500' fontSize='md' fontWeight='700'>
              On track
            </Text>
          </Flex>
        </Flex> */}
        <Box minH='260px' minW='100%' mt='auto'>
          <LineChart
            chartData={result?.lineChartDataTotalSpent || []}
            chartOptions={{ ...lineChartOptionsTotalSpent, xaxis: { ...lineChartOptionsTotalSpent.xaxis, categories: result?.month } }}
          />
        </Box>
      </Flex>
    </Card>
  );
}
