// js
import { api } from './api';

// api
export async function UploadFile(file) {
    try {
        const body = new FormData();
        body.append('file', file);
        const response = await api.post('/uploads', body, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
